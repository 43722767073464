import React from 'react';
import { useQuery, gql } from '@apollo/client';
import PageTitle from '../../components/Common/PageTitle';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { StrapiHelper } from '../../helpers/strapiHelper';
import './BlogArticle.css';
import StrapiImage from '../../components/Common/StrapiImage';

const GET_ARTICLE = gql`
  query GetArticle($filters: ArticleFiltersInput) {
    articles(filters: $filters) {
      title
      date
      intro
      image {
        url
      }
      isImageEmbedded
      body
      categories {
        title
        pageId
      }
    }
  }
`;

function BlogArticle() {
  // get page id
  const { id } = useParams();
  const OPTIONS = {
    variables: {
      filters: {
        pageId: {
          eq: id,
        },
      },
    },
  };

  const { loading, error, data } = useQuery(GET_ARTICLE, OPTIONS);
  if (loading) return <div>Loading...</div>;
  const apiPrblm = error || loading || !data.articles;
  const article = data.articles[0];

  return (
    <React.Fragment>
      <div className='container my-5 article'>
        <PageTitle title={article.title}></PageTitle>
        {article.isImageEmbedded ? (
          <StrapiImage
            url={article.image.url}
            title={article.title}
            cssClass='article-logo mb-4'
          />
        ) : (
          ''
        )}
        <div>{parse(StrapiHelper.buildHtmlClasses(article.body))}</div>
      </div>
    </React.Fragment>
  );
}

export default BlogArticle;
