import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageTitle from '../../components/Common/PageTitle';
import BlogArticlesList from './sections/BlogArticlesList';
import './Blog.css';
import CategoriesList from './sections/CategoriesList';
import Loader from '../../components/Common/Loader';

const GET_ARTICLES = gql`
  query GetArticles(
    $locale: I18NLocaleCode
    $sort: [String]
    $filters: ArticleFiltersInput
  ) {
    categories: articleCategories {
      pageId
      title
    }
    articles(locale: $locale, sort: $sort, filters: $filters) {
      pageId
      date
      title
      intro
      categories {
        title
      }
      image {
        url
      }
    }
  }
`;
const OPTIONS = (categories) => {
  return {
    variables: {
      locale: 'en',
      sort: 'date:DESC',
      filters:
        categories?.length > 0
          ? {
              categories: {
                pageId: {
                  in: categories,
                },
              },
            }
          : null,
    },
  };
};

function Blog() {
  const [searchParams] = useSearchParams();
  let urlCats = searchParams.getAll('cat');
  const [categoriesFilter, setFilter] = useState(urlCats);

  console.log(categoriesFilter);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(
    GET_ARTICLES,
    OPTIONS(categoriesFilter)
  );

  if (loading) return <Loader />;
  const apiPrblm = error || loading || !data.articles;
  let categories = [];

  function handleCategoryClick(catPageId) {
    let urlCats = searchParams.getAll('cat');
    let selectedCats = urlCats.includes(catPageId)
      ? urlCats.filter((x) => x !== catPageId)
      : [...urlCats, catPageId];

    let newCatUrl = '?';
    selectedCats.forEach((cat) => {
      newCatUrl += `cat=${cat}&`;
    });
    newCatUrl = newCatUrl.substring(0, newCatUrl.length - 1);

    navigate(newCatUrl.length > 3 ? `/blog${newCatUrl}` : '/blog');
    setFilter(selectedCats);
  }

  if (!apiPrblm) {
    let urlCats = searchParams.getAll('cat');

    categories = data.categories.map((cat) => ({
      ...cat,
      isActive: urlCats.includes(cat.pageId),
    }));

    // console.log(categories);
  }

  return (
    <React.Fragment>
      <div className='container my-5'>
        <div className='row mb-3'>
          <div className='col'>
            <PageTitle title={t('blog.title')}></PageTitle>
          </div>
        </div>
        <CategoriesList
          categories={categories}
          onCategoryClick={(catPageId) => handleCategoryClick(catPageId)}
        />
        <BlogArticlesList articles={data.articles} />
      </div>
    </React.Fragment>
  );
}

export default Blog;
