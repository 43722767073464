import React from 'react';
import './Courses.css';
import PageTitle from '../../components/Common/PageTitle';
import SectionCoursesList from './SectionCoursesList/SectionCoursesList';
import useFetch from '../../hooks/useFetch';

function Courses() {
  const { loading, error, data } = useFetch('');

  return (
    <React.Fragment>
      <div className='container my-5 courses'>
        <div className='text-content'>
          <PageTitle title='Our Courses' />
          <p>
            Discover your path to becoming a skilled beauty professional with
            our comprehensive range of courses. Whether you’re a beginner or an
            experienced beautician, we offer tailored programs that empower you
            to master essential techniques, learn the latest industry trends,
            and build a successful beauty career.
          </p>
          <h5>Our Courses Include:</h5>
          <ul>
            <li>
              <strong>Cosmetic chemistry from scratch</strong>: Explore cosmetic
              science, mastering ingredients and formulations to choose or
              create effective products - perfect for personal or professional
              use.
            </li>
            <li>
              <strong>ITEC Without Borders</strong>: Prepare for the
              International ITEC Certification to master essential skills and
              knowledge for foundational beauty training to confidently prepare
              for the ITEC exam.
            </li>
          </ul>
          <p>
            Each course is taught by experienced instructors and includes
            hands-on practice, so you can confidently apply your skills in a
            real-world setting. We also offer flexible class schedules, online
            resources, and support to help you reach your full potential.
          </p>
          <h5>Why Choose Us?</h5>
          <ul>
            <li>
              <strong>Certified Instructors</strong>: Learn from the best in the
              industry with certified, experienced instructors dedicated to
              helping you succeed.
            </li>
            <li>
              <strong>Professional Training</strong>: Our courses are designed
              to meet the highest industry standards, ensuring you gain
              relevant, up-to-date skills.
            </li>
            <li>
              <strong>Career Opportunities</strong>: Upon completing our
              courses, you'll have access to career counseling, internships, and
              job placements to kickstart your journey in beauty.
            </li>
          </ul>
          <p>
            Transform your passion for beauty into a fulfilling career. Enroll
            today to start your journey with confidence and style!
          </p>
        </div>

        <SectionCoursesList />
      </div>
    </React.Fragment>
  );
}

export default Courses;
